var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stars" },
    [
      _vm._l(Math.floor(_vm.hotelRating), function (star) {
        return _c(
          "span",
          { key: "starOk-" + star, staticClass: "StarOk" },
          [_c("StarOk")],
          1
        )
      }),
      _vm._l(!Number.isInteger(_vm.hotelRating) ? 1 : 0, function (star) {
        return _c(
          "span",
          { key: "starHalf-" + star, staticClass: "StarHalf" },
          [_c("StarHalf")],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }