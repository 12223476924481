var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "34",
        height: "33",
        viewBox: "0 0 34 33",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M17.2625 0V29L11.2254 32.4406C9.65055 33.2254 7.81321 33.2254 6.5008 32.1789C5.18839 31.1325 4.66333 29.3011 4.92581 27.7314L6.5008 21.191L1.51358 16.7435C0.201168 15.697 -0.323796 13.8657 0.201168 12.296C0.726132 10.7263 2.30109 9.41817 3.87598 9.41817L10.7004 8.89498L13.3253 2.61618C14.1127 1.04647 15.6876 0 17.2625 0Z",
          fill: "#FBB03B",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }