var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "34",
        height: "33",
        viewBox: "0 0 34 33",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M30.3866 9.41817L23.8246 8.89498L21.1997 2.61618C20.6748 1.04647 19.0999 0 17.2625 0C15.6876 0 14.1127 1.04647 13.3253 2.61618L10.7004 8.89498L3.87598 9.41817C2.30109 9.41817 0.726132 10.7263 0.201168 12.296C-0.323796 13.8657 0.201168 15.697 1.51358 16.7435L6.5008 21.191L4.92581 27.7314C4.66333 29.3011 5.18839 31.1325 6.5008 32.1789C7.81321 33.2254 9.65055 33.2254 11.2254 32.4406L17.0001 29.0395L22.7747 32.4406C23.5622 32.7022 24.3496 32.9637 24.8745 32.9637C25.662 32.9637 26.4494 32.7022 27.4994 32.1789C28.8118 31.1325 29.5992 29.3011 29.0742 27.7314L27.4994 21.191L32.4864 16.7435C33.7988 15.697 34.3238 13.8657 33.7988 12.296C33.5364 10.7263 32.224 9.67978 30.3866 9.41817Z",
          fill: "#FBB03B",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }